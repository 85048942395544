import React from "react"
import { Link } from "gatsby"
import layoutStyles from "../layout.module.css"

export default function PRO004Overview(props) {
    return(
    <table cellPadding="0" border="0">
      <tr>
      <td> <Link to="../../PRO004/PRO004_ProjectRequirements" style={{ textDecoration:"none" ,textShadow: `none`, backgroundImage: `none` }}>Project Requirements</Link></td>
      <td >August 4th 2021</td>
    </tr>
    <tr>
      <td><Link to="../../PRO004/PRO004_IntermediateSteps" style={{ textDecoration:"none" ,textShadow: `none`, backgroundImage: `none` }}>Step for step - Step 1</Link></td>
      <td>November 20th 2021</td>
    </tr>
    </table>
)}