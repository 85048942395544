import React from "react"
import { Link } from "gatsby"
import layoutStyles from "../layout.module.css"

export default function PRO002Overview(props) {
    return(
        <div>
        <table cellPadding="0" border="0">
      <tr>
      <td> <Link to="../../PRO002/PRO002_intro" style={{ textDecoration:"none" ,textShadow: `none`, backgroundImage: `none` }}>Project introduction</Link></td>
      <td >August 7th 2020</td>
    </tr>
    <tr>
      <td><Link to="../../PRO002/PRO002_TestSetup" style={{ textDecoration:"none" ,textShadow: `none`, backgroundImage: `none` }}>Setting up a setup</Link></td>
      <td >September 13th 2020</td>
    </tr>
    <tr>
      <td><Link to="../../PRO002/PRO002_Nodemcuv2" style={{ textDecoration:"none" ,textShadow: `none`, backgroundImage: `none` }}>Move to the ESP</Link></td>
      <td>October 19th 2020</td>
    </tr>
    </table>
    </div>
    )}