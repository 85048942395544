import React from "react"
import Layout from "../components/layout"
import PhonePrev from "../components/PhonePrev"
import FarmPrev from "../components/FarmPrev"
import BatteryPrev from "../components/BatteryPrev"
import background from "../Figures/Site_Header_Background.png"
import indexStyles from "./index.module.css"
import TarmoPrev from "../components/TarmoPrev"

export default function Home() {
  return (
    <div>
    <Layout>
      {/*<img src={background} alt="tryout" /> */}
      </Layout>
      <div style={{backgroundImage: "url(" + background + ")", backgroundSize: "cover", minHeight: "200px"}} >
        <div className={indexStyles.header} style={{}}>
      <h1 style={{color: "white", whiteSpace:"nowrap"}}>Welcome!</h1>
      <h2 style={{color: "white"}}>Just an engineer, <br /> sharing the projects he is working on and the frustrations along the way.</h2>
        </div>
      </div>
      <div className={indexStyles.top}>
        <h1> Recent projects: </h1>
      </div>
      <BatteryPrev></BatteryPrev>
      <TarmoPrev></TarmoPrev>
      <FarmPrev></FarmPrev>
      <PhonePrev></PhonePrev>
      </div>
  )
}