import React from "react"
import { Link } from "gatsby"
import avatar_PRO001 from "../Figures/PRO004_SystemDiagramBattery.jpg"
import layoutStyles from "./indexLayout.module.css"
import Overviewtable from "../components/ProjectOverviews/PRO004Overview.js"


export default function BatteryPrev() {
  return (
    <div className={layoutStyles.top}>
    <div>
    <table cellPadding="0" border="0" className={layoutStyles.table} style={{}}>
      <tr>
        <td rowSpan="2" style={{marginBottom:"0", width:"30%"}}><img  src={avatar_PRO001} style={{borderRadius: 20, marginBottom:"0"}} alt="avatar PRO001" /></td>
        <th  align="left" style={{marginBottom:"0"}}>PRO004 - DIY-ish Home battery </th>
      </tr>
      <tr>
        <td  align="left" style={{marginBottom:0}}>Status: &#11035;&#11036;&#11036;&#11036; </td>
      </tr>
      <tr>
        <td colSpan="2">After putting up some solar panels last year and the Belgian government stopping some of the initiatives when putting energy back on the grid I wanted to try and connect a small battery modue to try and supply just a couple of  consumers.</td>
      </tr>
      </table>
      </div>
      <div className={layoutStyles.table}>
        <Overviewtable ></Overviewtable>
      </div>
      </div>
  )
}