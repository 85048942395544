import React from "react"
import { Link } from "gatsby"
import layoutStyles from "../layout.module.css"

export default function PRO001Overview(props) {
    return(
    <table cellPadding="0" border="0">
      <tr>
      <td> <Link to="../../PRO001/RotaryDial" style={{ textDecoration:"none" ,textShadow: `none`, backgroundImage: `none` }}>Reading the rotary dial</Link></td>
      <td >June 28th 2020</td>
    </tr>
    <tr>
    <td><Link to="../../PRO001/BellRinging" style={{ textShadow: `none`, backgroundImage: `none` }}>Ringing the bell</Link></td>
      <td >July 12th 2020</td>  
    </tr>
    <tr>
      <td><Link to="../../PRO001/CellLinking" style={{ textShadow: `none`, backgroundImage: `none` }}>Linking to the cell module</Link></td>
      <td >August 1st 2020</td>  
    </tr>
    </table>
)}