import React from "react"
import { Link } from "gatsby"
import avatar_PRO002 from "../Figures/PRO002_update.jpg"
import layoutStyles from "./indexLayout.module.css"
import Overviewtable from "../components/ProjectOverviews/PRO002Overview.js"



export default function FarmPrev() {
  return (
    <div className={layoutStyles.top}>
    <div>
    <table cellPadding="0" border="0" className={layoutStyles.table}>
      <tr>
        <td rowSpan="2" style={{marginBottom:"0", width:"30%"}}><img  src={avatar_PRO002} style={{borderRadius: 20, marginBottom:"0"}} alt="avatar PRO001" /></td>
        <th  align="left" style={{marginBottom:"0"}}>PRO002- Hydroponic Farm </th>
      </tr>
      <tr>
        <td  align="left" style={{marginBottom:0}}>Status: &#11035;&#11035;&#11035;&#11036; </td>
      </tr>
      <tr>
        <td colSpan="2">Random searches on the internet made me somehow very interested in hydroponic farming and I would like to set up a small farm of my own.</td>
      </tr>
    </table>
    </div>
      <div className={layoutStyles.table}>
        <Overviewtable ></Overviewtable>
      </div>
      </div>
  )
}