import React from "react"
import { Link } from "gatsby"
import avatar_PRO001 from "../Figures/PRO001_avatar.jpg"
import layoutStyles from "./indexLayout.module.css"
import Overviewtable from "../components/ProjectOverviews/PRO001Overview.js"


export default function PhonePrev() {
  return (
    <div className={layoutStyles.top}>
    <div>
    <table cellPadding="0" border="0" className={layoutStyles.table} style={{}}>
      <tr>
        <td rowSpan="2" style={{marginBottom:"0", width:"30%"}}><img  src={avatar_PRO001} style={{borderRadius: 20, marginBottom:"0"}} alt="avatar PRO001" /></td>
        <th  align="left" style={{marginBottom:"0"}}>PRO001- Rotary cellphone </th>
      </tr>
      <tr>
        <td  align="left" style={{marginBottom:0}}>Status: &#11035;&#11035;&#11035;&#11035; </td>
      </tr>
      <tr>
        <td colSpan="2">Here I convert an old rotary dial phone to a cellphone, Reading in the rotary dial, activating the ringer and with it's own simcard. Using an Arduino and a SIM900 gsm-shield.</td>
      </tr>
      </table>
      </div>
      <div className={layoutStyles.table}>
        <Overviewtable ></Overviewtable>
      </div>
      </div>
  )
}